// Admin
import Investors from "../pages/admin/Investors";
import Advisors from "../pages/admin/Advisors";
import Programs from "../pages/admin/programs/Programs";
import Funds from "../pages/admin/funds/Funds";
import Documents from "../pages/admin/documents/Documents";
import Statements from "../pages/admin/Statements";
import ProgramReview from "../pages/admin/programs/review/ProgramReview";
import ProgramDetails from "../pages/admin/programs/details/ProgramDetail";
import FundDetails from "../pages/admin/funds/FundDetail";
import Reports from "../pages/admin/reports/Reports";

//Customer
import CustomerLanding from "../pages/customer/Landing";
import CustomerDocuments from "../pages/customer/Documents";
import CustomerProgram from "../pages/customer/Program";
import CustomerFund from "../pages/customer/Fund";

//Printer
import ReadyToPrint from "../pages/printer/ReadyToPrint";

// Auth
import SignIn from "../pages/auth/signIn/SignIn";
import SignUp from "../pages/auth/SignUp";
import OnBoardManual from "../components/customer/onboard/OnBoardManual";
import ResetPassword from "../pages/auth/ResetPassword";
import Page404 from "../pages/auth/Page404";
import Page500 from "../pages/auth/Page500";
import ProgramDistributions from "../pages/admin/programs/Distributions";
import FundDistributions from "../pages/admin/funds/Distributions";
import DocumentsReview from "../pages/admin/documents/review/DocumentsReview";
import Categories from "../pages/admin/Categories";
import Sponsors from "../pages/admin/Sponsors";
import Users from "../pages/admin/Users";
import Settings from "../pages/admin/Settings";
import RecoverPassword from "../pages/auth/RecoverPassword";
import Activity from "../pages/admin/Activities";
import InvestorSelector from "../pages/advisor/InvestorSelector";
import Dashboard from "../pages/admin/programs/Dashboard";

import Account from "../pages/Account";
import Stakeholders from "../pages/admin/Stakeholders";

// Survey
import CreateAnswer from "../pages/survey/CreateAnswer";
import AddDistributions from "../pages/admin/programs/AddDistributions";

const USER_ROLE_ADMIN = "admin";
const USER_ROLE_SPONSOR = "sponsor";

// Routes
export const adminRoutes = (isCS = false, isSponsor = false) => [
  {
    path: "/back/investors",
    name: "Investors",
    navbar: true,
    component: Investors,
  },
  {
    path: "/back/stakeholders",
    name: "Stakeholders",
    navbar: true,
    component: Stakeholders,
  },
  {
    path: "/back/advisors",
    name: "Advisors",
    navbar: true,
    component: Advisors,
  },
  {
    path: "/back/programs",
    name: "Programs",
    navbar: true,
    component: Programs,
  },
  {
    path: "/back/programs/:programId",
    name: "Program Details",
    component: ProgramDetails,
  },
  {
    path: "/back/programs/:programId/distributions",
    name: "Program Details",
    component: ProgramDistributions,
  },
  {
    path: "/back/programs/:programId/dashboard",
    name: "Program Dashboard",
    component: Dashboard,
  },
  {
    path: "/back/funds/:fundId/fund-quarters/:fundQuarterId/distributions",
    name: "Program Details",
    component: FundDistributions,
  },
  {
    path: "/back/funds/:fundId/fund-months/:fundMonthId/distributions",
    name: "Program Details",
    component: FundDistributions,
  },
  {
    path: "/back/program/review",
    name: "Programs",
    navbar: false,
    component: ProgramReview,
  },
  {
    path: "/back/documents/review",
    name: "Documents",
    navbar: false,
    component: DocumentsReview,
  },
  {
    path: "/back/Statements/review",
    name: "Statements",
    navbar: false,
    component: DocumentsReview,
  },
  {
    path: "/back/funds",
    name: "Strategic Funds",
    navbar: true,
    component: Funds,
  },
  {
    path: "/back/funds/:fundId",
    name: "Strategic Fund Details",
    navbar: false,
    component: FundDetails,
  },
  {
    path: "/back/documents",
    name: "Documents",
    navbar: true,
    component: Documents,
  },
  {
    path: "/back/statements",
    name: "Statements",
    navbar: true,
    component: Statements,
  },
  {
    name: "Reports",
    navbar: isCS ? false : true,
    roles: [USER_ROLE_ADMIN, USER_ROLE_SPONSOR],
    path: "/back/reports",
    component: Reports,
  },
  {
    path: "/back/activity",
    name: "Activity",
    roles: [USER_ROLE_ADMIN],
    navbar: isCS || isSponsor ? false : true,
    component: Activity,
  },
  {
    path: "/back/categories",
    name: "Categories",
    navbar: false,
    component: Categories,
  },
  {
    path: "/back/sponsors",
    name: "Sponsors",
    navbar: false,
    component: Sponsors,
  },
  {
    path: "/back/users",
    name: "Users",
    navbar: false,
    component: Users,
  },
  {
    path: "/back/settings",
    name: "Settings",
    navbar: false,
    component: Settings,
  },
  {
    path: "/back/add-distributions/:year/:quarter",
    name: "Add Distributions",
    navbar: false,
    component: AddDistributions,
  },
];

export const customerRoutes = [
  {
    path: "/portal",
    name: "Dashboard",
    component: CustomerLanding,
  },
  {
    path: "/portal/sponsor/:sponsorId/program/:programId",
    name: "Program",
    component: CustomerProgram,
  },
  {
    path: "/portal/funds/:fundId",
    name: "Fund",
    component: CustomerFund,
  },
  {
    path: "/portal/documents",
    name: "Documents",
    component: CustomerDocuments,
  },
  {
    path: "/portal/account",
    name: "My Account",
    component: Account,
  },
];

export const advisorRoutes = [
  {
    path: "/investor-selector",
    name: "Investor Selector",
    component: InvestorSelector,
  },
  {
    path: "/account",
    name: "My Account",
    component: Account,
  },
];

export const printerRoutes = [
  {
    path: "/ready-to-print",
    name: "Ready to Print",
    component: ReadyToPrint,
    navbar: true,
  },
];

export const authRoutes = [
  {
    path: "/auth/sign-in",
    name: "Sign In",
    component: SignIn,
  },
  {
    path: "/auth/sign-in/:sponsorName",
    name: "Sign In",
    component: SignIn,
  },
  {
    path: "/auth/sign-up",
    name: "Sign Up",
    component: SignUp,
  },
  {
    path: "/onboard/manual",
    name: "Onboard Manual",
    component: OnBoardManual,
  },
  {
    path: "/auth/reset",
    name: "Reset Password",
    component: ResetPassword,
  },
  {
    path: "/auth/forgot/:code",
    name: "Recover Password",
    component: RecoverPassword,
  },
  {
    path: "/auth/404",
    name: "404 Page",
    component: Page404,
  },
  {
    path: "/auth/500",
    name: "500 Page",
    component: Page500,
  },
];

export const surveyRoutes = [
  {
    path: "/survey/:token",
    name: "Survey",
    component: CreateAnswer,
  },
];
