import React, { useEffect, useState } from "react";

import { Card, Table } from "reactstrap";

import { useAuth } from "../../providers/authProvider";
import Loader from "../Loader";
import { utils } from "../../utils/utils";
import {
  faChevronDown,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";

const TYPE_PRIVATE_EQUITY = 1;
const TYPE_REAL_ESTATE = 2;
const TYPE_HEDGE_FUND = 3;

const getHedgeFundInvestments = (programs) => {
  const fundInvestments = programs
    .filter((p) => p.programTypeId === TYPE_HEDGE_FUND)
    .reduce((funds, program) => {
      const totalDistributions =
        program.programInvestment.monthDistributions.reduce(
          (p, c) => p + c.amount,
          0
        );
      const programInvestment = program.programInvestment;
      const fund = program.programFundInvestments[0]?.fundFundInvestment;
      if (!fund) {
        return funds;
      }
      const programUnits = programInvestment ? programInvestment.units : 0;
      funds[fund.id] = {
        programs: [
          ...(funds[fund.id]?.programs || []),
          {
            id: program.id,
            sponsorId: program.sponsorId,
            name: program.name,
            units: programUnits,
            investment: programInvestment.capital,
            totalReturn: totalDistributions,
            totalCapital: program.capital,
          },
        ],
        fund: {
          id: fund.id,
          name: fund.name,
          year: fund.year,
          capital: fund.fundFundInvestments.reduce((p, c) => p + c.amount, 0),
          units: (funds[fund.id]?.fund.units || 0) + programUnits,
          investment:
            (funds[fund.id]?.fund.investment || 0) +
            (programInvestment ? programInvestment.capital : 0),
          totalReturn:
            (funds[fund.id]?.fund.totalReturn || 0) + totalDistributions,
          totalCapital:
            (funds[fund.id]?.fund.totalCapital || 0) + program.capital,
        },
      };
      return funds;
    }, {});
  return Object.values(fundInvestments);
};

const InvestmentsOverview = ({ title, types }) => {
  const [authContext] = useAuth();
  const [programs, setPrograms] = useState();
  const [fundInvestments, setFundInvestments] = useState();

  const user = authContext.currentUser;

  useEffect(() => {
    const programs = [...user.investments].filter(
      (i) => types.indexOf(i.programTypeId) > -1
    );
    setPrograms(programs);
  }, [user.investments, types]);

  const FundInvestmentRow = ({ fundInvestment }) => {
    const [expanded, setExpanded] = useState(false);

    return (
      <>
        <tr
          onClick={() => setExpanded(!expanded)}
          className="font-weight-bold bg-lighter cursor-pointer"
        >
          <td>
            <FontAwesomeIcon
              className="font-size-75 align-baseline text-muted font-weight-normal mr-2"
              icon={expanded ? faChevronDown : faChevronRight}
              fixedWidth
            />
          </td>
          <td className="text-left">
            <Link to={`/portal/funds/${fundInvestment.fund.id}`}>
              {fundInvestment.fund.name} {fundInvestment.fund.year}
            </Link>
          </td>
          <td>{fundInvestment.fund.units}</td>
          <td>{utils.formatCurrency(fundInvestment.fund.investment)}</td>
          <td className="small test-muted">{"N/A"}</td>
          {fundInvestment.fund.totalReturn > 0 ? (
            <>
              <td>{utils.formatCurrency(fundInvestment.fund.totalReturn)}</td>
              <td>
                {(
                  (fundInvestment.fund.totalReturn /
                    fundInvestment.fund.totalCapital) *
                  100
                ).toFixed(1)}
                x
              </td>
            </>
          ) : (
            <>
              <td></td>
              <td></td>
            </>
          )}
        </tr>
        {fundInvestment.programs.map((program, index) => (
          <tr hidden={!expanded} key={index}>
            <td></td>
            <td className="text-left">
              <Link
                to={`/portal/sponsor/${program.sponsorId}/program/${program.id}`}
              >
                {program.name}
              </Link>
            </td>
            <td>{program.units}</td>
            <td>{utils.formatCurrency(program.investment)}</td>
            <td className="text-muted small">N/A</td>
            {program.programDistributions?.length ? (
              <>
                <td>{utils.formatCurrency(program.totalReturn)}</td>
                <td>
                  {((program.totalReturn / program.totalCapital) * 100).toFixed(
                    1
                  )}
                  x
                </td>
              </>
            ) : (
              <>
                <td></td>
                <td></td>
              </>
            )}
          </tr>
        ))}
      </>
    );
  };

  const InvestmentRow = ({ program }) => {
    const totalDistributions = program.programDistributions
      .flatMap((pd) => pd.distributions)
      .reduce((p, c) => p + c.amount, 0);

    return (
      <>
        <tr>
          <td></td>
          <td className="text-left">{program.name}</td>
          <td>{program.programInvestment.units}</td>
          <td>{utils.formatCurrency(program.programInvestment.capital)}</td>
          <td>{program.programStatus?.name || "Not Set"}</td>
          {program.programDistributions?.length ? (
            <>
              <td>{utils.formatCurrency(totalDistributions)}</td>
              <td>
                {utils.formatPercent(totalDistributions, program.capital)}
              </td>
            </>
          ) : (
            <>
              <td></td>
              <td></td>
            </>
          )}
        </tr>
      </>
    );
  };

  useEffect(() => {
    const fundInvestments = getHedgeFundInvestments([...user.investments]);
    setFundInvestments(fundInvestments);
  }, [user.investments]);

  const hasDistributions =
    programs?.filter((program) => program.programDistributions?.length)
      ?.length > 0;

  return !programs ? (
    <Loader />
  ) : !programs.length ? null : (
    <Card className=" w-100">
      <Table className="my-0 text-custom-dark text-center">
        <thead>
          <tr>
            <th colSpan={6} className="text-left">
              <span className="text-custom-red font-size-1-2">
                {title} Investments
              </span>
            </th>
          </tr>
          <tr className="text-muted font-size-75">
            <th></th>
            <th className="text-left">Fund Name</th>
            <th>Units</th>
            <th>My Investment</th>
            <th>Status</th>
            {hasDistributions ? (
              <>
                <th>Total Return</th>
                <th>MOIC</th>
              </>
            ) : (
              <>
                <th></th>
                <th></th>
              </>
            )}
          </tr>
        </thead>
        <tbody>
          <>
            {fundInvestments
              .sort((a, b) => b.fund.year - a.fund.year)
              .map((fundInvestment) => (
                <FundInvestmentRow
                  fundInvestment={fundInvestment}
                  key={`fund-${fundInvestment.fund.id}`}
                />
              ))}
            {programs
              .filter(
                (program) =>
                  program.programTypeId === TYPE_PRIVATE_EQUITY ||
                  program.programTypeId === TYPE_REAL_ESTATE
              )
              .map((program) => (
                <InvestmentRow
                  program={program}
                  key={`program-${program.id}`}
                />
              ))}
          </>
        </tbody>
      </Table>
    </Card>
  );
};

export default InvestmentsOverview;
