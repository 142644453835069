import React from "react";
import { Container, Col } from "reactstrap";

import Header from "../../components/Header";
import HeaderTitle from "../../components/HeaderTitle";
import HeaderSubtitle from "../../components/HeaderSubtitle";
import { useAuth } from "../../providers/authProvider";
import AlternativeInvestmentsOverview from "../../components/customer/AlternativeInvestmentsOverview";
import { utils } from "../../utils/utils";
import InvestmentsOverview from "../../components/customer/InvestmentsOverview";
import { PRINTER } from "../../utils/roles";

const TYPE_PRIVATE_EQUITY = 1;
const TYPE_REAL_ESTATE = 2;
const TYPE_HEDGE_FUND = 3;

const Landing = () => {
  const [authContext] = useAuth();
  const user = authContext.currentUser;

  const isAdvisor =
    authContext.currentUser?.fakeLogin &&
    utils.userHasRole(authContext.currentUser.sessionOwner, "advisor") &&
    authContext.currentUser.id !== authContext.currentUser.sessionOwner.id;

  const userInvestments = authContext.currentUser?.investments || [];
  const userOwnerships = authContext.currentUser?.ownerships || [];

  let sponsors = [];
  if (!utils.userHasRole(authContext.currentUser.sessionOwner, PRINTER)) {
    sponsors = utils
      .sidebarSponsors([...userInvestments, ...userOwnerships])
      .sort((x, y) => (y.name.toLowerCase() < x.name.toLowerCase() ? 1 : -1));
  }

  const sidebarLogo = sponsors.length === 1 ? sponsors[0].webLogo : null;

  return (
    <Container fluid>
      <Header>
        <HeaderTitle>
          {user.isNewUser ? "Welcome" : "Welcome back"}, {user?.name}!
        </HeaderTitle>
        <HeaderSubtitle>
          {isAdvisor ? `${user?.name}’s Investments` : "My Investment Overview"}
        </HeaderSubtitle>
      </Header>
      <Col className="d-flex col-12 flex-column flex-grow-1">
        <InvestmentsOverview
          title={"Private Equity"}
          types={[TYPE_PRIVATE_EQUITY, TYPE_HEDGE_FUND]}
        />
        <InvestmentsOverview title={"Real Estate"} types={[TYPE_REAL_ESTATE]} />
        <AlternativeInvestmentsOverview />
        <div
          className="flex-grow-1 col-6 offset-3"
          style={
            sidebarLogo
              ? {
                  backgroundImage: `url(${sidebarLogo})`,
                  opacity: 0.15,
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "contain",
                }
              : undefined
          }
        ></div>
      </Col>
    </Container>
  );
};

export default Landing;
